import { isClient } from "@/utils/common.util";

export const statsEveryPage = (url: string, username?: string) => {
  if (isClient) {
    window._paq.push(["setRequestMethod", "POST"]);
    window._paq.push(["enableHeartBeatTimer", 15]);
    if (username) {
      window._paq.push(["setUserId", username]);
    }
    window._paq.push(["setReferrerUrl", document.referrer]);
    window._paq.push(["setCustomUrl", url]);
    window._paq.push(["setDocumentTitle", document.title]);
    window._paq.push(["deleteCustomVariables", "page"]);
    window._paq.push(["setGenerationTimeMs", 0]);
    window._paq.push(["trackPageView"]);
    window._paq.push(["enableLinkTracking"]);
    window._paq.push([
      "trackContentImpressionsWithinNode",
      document.getElementById("root"),
    ]);
  }
};

export const statsLogout = () => {
  if (isClient) {
    window._paq.push(["setRequestMethod", "POST"]);
    window._paq.push(["resetUserId"]);
    window._paq.push(["appendToTrackingUrl", "new_visit=1"]);
    window._paq.push(["appendToTrackingUrl", ""]);
  }
};

export const statsSearch = (term: string, count: number, target: string) => {
  if (isClient) {
    window._paq.push(["setRequestMethod", "POST"]);
    window._paq.push(["trackSiteSearch", term, target, count]);
  }
};

export const statsTranslate = (targets: string[]) => {
  if (isClient) {
    window._paq.push(["setRequestMethod", "POST"]);

    window._paq.push([
      "trackEvent",
      "translate",
      "youdao",
      ["translate"],
      targets?.length,
    ]);
  }
};

export const useMatomo = () => {
  if (isClient) {
    return {
      statsEveryPage,
      statsLogout,
      statsSearch,
      statsTranslate,
    };
  }
  return {};
};
